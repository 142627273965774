import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SubSenseConfirmScript from "../../components/SubSenseConfirmScript";
import CurvedArrow from "react-curved-arrow";
import { Script } from "gatsby";



// eslint-disable-next-line
const PleaseConfirm = () => {
  const [canRender, setCanRender] = useState(false);

  const gtag_conversion_id = process.env.GATSBY_GTAG_CONVERSION_ID_PLEASE_CONFIRM || null;

  useEffect(() => setCanRender(true));

  return (
    <Layout pageTitle="One More Step | Subscribe Sense" pageDescription="Thank you for subscribing to our mailing list." footer_children={<SubSenseConfirmScript/>}>
      {gtag_conversion_id &&
        <Script>{`typeof window !== "undefined" && window.gtag('event', 'conversion', {'send_to': '${gtag_conversion_id}'});`}</Script>
      }
      <section className="section">
        {canRender ? <div>
          <div className="lg:block hidden">
            <CurvedArrow fromSelector="#IntroArrowStartLg" toSelector="#IntroArrowEndLg" middleY={100} fromOffsetX={50} toOffsetX={-25} dynamicUpdate={true} color="#c2007cbd" width={6}/>
          </div>
          <div className="lg:hidden">
            <CurvedArrow fromSelector="#IntroArrowStartSm" toSelector="#IntroArrowEndSm" fromOffsetY={-15} fromOffsetX={-15} toOffsetX={-25} middleX={-50}  dynamicUpdate={true} color="#c2007cbd" width={6}/>
          </div>

          <div className="lg:block hidden">
            <CurvedArrow fromSelector="#StatusArrowStartLg" toSelector="#StatusArrowEndLg" middleY={100} fromOffsetX={50} toOffsetX={-25} toOffsetY={10} dynamicUpdate={true} color="#c2007cbd" width={6} zIndex={10}/>
          </div>
          <div className="lg:hidden">
            <CurvedArrow fromSelector="#StatusArrowStartSm" toSelector="#StatusArrowEndSm" fromOffsetY={25} fromOffsetX={-25} toOffsetX={15} toOffsetY={-25} middleX={80} middleY={10} dynamicUpdate={true} color="#c2007cbd" width={6}/>
          </div>

          <div className="lg:block hidden">
            <CurvedArrow fromSelector="#SniperLinkArrowStartLg" toSelector="#SniperLinkArrowEndLg" middleX={150} fromOffsetY={50} fromOffsetX={10} toOffsetX={15} toOffsetY={10} dynamicUpdate={true} color="#c2007cbd" width={6} zIndex={10}/>
          </div>
          <div className="lg:hidden">
            <CurvedArrow fromSelector="#SniperLinkArrowStartSm" toSelector="#SniperLinkArrowEndSm" fromOffsetY={-15} fromOffsetX={-5} toOffsetX={25} toOffsetY={15} middleX={100} middleY={10} dynamicUpdate={true} color="#c2007cbd" width={6}/>
          </div>
        </div> : null }
        <div className="container">
          <div className="bg-white">
            <div className="py-8 mx-8 lg:mx-0 overflow-hidden sm:py-12 lg:relative lg:py-12">
              <div className="lg:float-left lg:max-w-[20%] lg:pt-20">
                <span id="IntroArrowStartLg" className=""/>
                <span id="IntroArrowStartSm" className=""/>
                <p className="font-handwritten text-4xl text-indigo opacity-75">
                  A real confirmation page using Subsribe Sense.
                </p>
              </div>
              <div className="mx-auto max-w-md sm:max-w-3xl px-4 sm:px-6 lg:px-8">
                <div>
                  <div className="my-6 sm:max-w-3xl">
                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    <span id="IntroArrowEndLg"/>
                    <span id="IntroArrowEndSm"/>
                      One more step...
                    </h1>
                    <p className="mt-6 text-xl text-gray-500">We need to confirm your email address to complete the subscription process.</p>
                  </div>
                </div>
              </div>
              <div className="lg:float-right lg:max-w-[15%]">
                <span id="StatusArrowStartLg"/>
                <p className="font-handwritten text-3xl text-indigo opacity-75">Status bar updates on a bounce or completed confirmation.</p>
                <span id="StatusArrowStartSm" class="float-right"/>
              </div>
              <div className="mx-auto max-w-md sm:max-w-3xl px-4 sm:px-6 lg:px-8">
                <div> 
                  <span id="StatusArrowEndLg" className="float-right"/>
                  <span id="StatusArrowEndSm" className="float-right"/> 
                  <div className="subsense-status-alert my-5">
                  </div>
                </div>
              </div>
              <div className="mx-auto max-w-md sm:max-w-3xl px-4 sm:px-6 lg:px-8">
                <div>
                  <div>
                    <div className="">
                      <div className="flex flex-col">
                        <div>
                          <div className="subsense-sniper-link text-center mt-3 px-6 py-3 border-2 border-indigo-600 text-base font-medium rounded-md text-gray-700 bg-white shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200">
                          </div>
                          <span id="SniperLinkArrowEndLg" className="float-right"/>
                          <span id="SniperLinkArrowEndSm" className="float-right"/>
                        </div>
                        <div>
                          <p className="text-center text-gray-700">Click the confirm link in our email and make sure we're not marked as spam.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-w-4xl">
                <span id="SniperLinkArrowStartSm" className="float-right"/>
                <p className="font-handwritten text-3xl text-indigo opacity-75">"Sniper Link" knows your mail provider and pulls up confirmation email. (Even in spam folder for some providers!)</p>
                <span id="SniperLinkArrowStartLg" className="float-right"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default PleaseConfirm;