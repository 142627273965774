import * as React from "react";
import { Script } from "gatsby";

const SubSenseConfirmScript = function () {
  let ss_script_domain = process.env.GATSBY_SS_SCRIPT_DOMAIN + "/loader_conf_script.js"
  return (
    <Script
    data-teamslug='subscribe-sense'
    data-listuuid={process.env.GATSBY_SS_LIST_UUID}
    src={ss_script_domain}
    />
  );
}

export default SubSenseConfirmScript;
